import React from 'react'

import PageLayout from 'components/PageLayout/F'
import ContactSupport from 'pages/Login/ContactSupport'
import Form from 'pages/Login/Form'
import InvestInSS from 'pages/Login/InvestInSS'

function Login() {
  return (
    <PageLayout
      leftBottomChildren={<ContactSupport />}
      rightChildren={<InvestInSS />}
    >
      <Form sx={{ maxWidth: 320 }} />

      {/* TODO: Uncomment when marketing asks
      <Box
        sx={{
          height: '7%',
          maxHeight: 80,
        }}
      />

      <TrustpilotCard
        sx={{
          height: '100%',
          maxHeight: 58,
          maxWidth: 320,
          width: '100%',
        }}
      /> */}
    </PageLayout>
  )
}

export default Login
