import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import LanguageProvider from 'components/LanguageProvider'
import StyleProvider from 'components/StyleProvider'
import Login from 'pages/Login/Login'
import PublicAppApolloClient from 'utils/apollo/globalClient'
import { setWindowLocaleName } from 'utils/systemLocales'

setWindowLocaleName()

// String translation
window.T = function T(str, options = {}) {
  str =
    (typeof window._T === 'object' && window._T[str]) ||
    (typeof window._GT === 'object' && window._GT[str]) ||
    str ||
    ''

  return str.replace(/\{\{(\w+)}}/g, (_str, m1) => String(options[m1]))
}

const client = PublicAppApolloClient()

function AppLogin() {
  return (
    <StrictMode>
      <LanguageProvider apolloClient={client}>
        <StyleProvider>
          <Login />
        </StyleProvider>
      </LanguageProvider>
    </StrictMode>
  )
}

const render = () => {
  const rootElement = document.querySelector('#root')

  if (!rootElement) return
  const root = createRoot(rootElement)

  root.render(<AppLogin />)
}

;['complete', 'interactive'].includes(document.readyState)
  ? render()
  : window.addEventListener('DOMContentLoaded', render)
